<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-text>
            <v-date-picker
              v-model="filters.date"
            ></v-date-picker>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-text>
            <v-radio-group v-model="filters.userType" label="Данные по ">
              <v-radio
                label="Каналу"
                value="channel"
              ></v-radio>
              <v-radio
                label="Боту"
                value="bot"
              ></v-radio>
            </v-radio-group>
            <v-radio-group v-model="filters.statType" label="Показать">
              <v-radio
                label="Последнюю публикацию"
                value="lastPub"
              ></v-radio>
              <v-radio
                label="Все публикации"
                value="total"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="filters.statType === 'lastPub'">
      <v-col cols="12">
        <v-data-table
          :items="channels"
          :headers="headers"
          :items-per-page="100"
          sort-by="common_stats.posted_days_ago"
          dense
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-else>
      <ad-channel-total-stats :filters="filters"></ad-channel-total-stats>
    </v-row>
  </v-container>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import store from "@/store";
import moment from "moment";

export default {
  name: "AdChannelsStats",
  components:{
    AdChannelTotalStats: ()=>import('./AdChannelTotalStats.vue')
  },
  setup() {
    //date is always today
    const filters = ref({
      dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      userType: 'bot',
      statType: 'lastPub',
    })
    const headers = ref([
      {text: 'Админ', value: 'admin_contact'},
      {text: 'Название канала', value: 'name'},
      {text: 'Дата посл.', value: 'common_stats.posted_at', sortable: false},
      {text: 'Дней ', value: 'common_stats.posted_days_ago'},
      {text: 'Цена', value: 'lastPub.price'},
      {text: 'Охват', value: 'lastPub.views'},
      {text: 'Подп', value: 'common_stats.users'},
      {text: 'CPI', value: 'common_stats.cpi'},
      {text: 'Коэф', value: 'common_stats.coeff'},
      {text: 'CPM', value: 'common_stats.cpm'},
      {text: 'Доход', value: 'common_stats.income'},
      {text: 'Прибыль', value: 'common_stats.profit'},
      {text: 'Комм.', value: 'comment'},


    ])
    const allChannels = computed(() => {
      return store.state.adChannels
    })
    const channels = computed(() => {
      //в каждый из allChannels.value добавляем поле lastPub, а сам allChannels нужно реверсировать
      const sorted = allChannels.value
        .reverse()
        .map(x=>{
          x.lastPub = getLastPub(x.id)
          if (x.lastPub) {
            x.lastPub.extra = getExtraStats(x.lastPub)
            x.lastPub.days_ago = getDaysAgo(x.lastPub.posted_at)
            x.common_stats = fillStats(x)
            return x
          }
          //а иначе - вообще не возвращаем этот канал
          return false
        })
      return sorted.filter(x=>x)
    })


    const realChannels = computed(()=>{
      return channels.value.map(x=>{
        x.common_stats = fillStats(x)
        return x
      })
    })

    const getLastPub = channel_id =>{
      const pubs = allPublications.value.filter(y=>y.ad_channel_id===channel_id && y.status === 'Опубликован')
      if (pubs.length){
        return pubs.sort((a,b)=>a.id<b.id?1:-1)[0]
      }
      return false
    }

    const getDaysAgo = date => {
      const today = moment()
      const pubDate = moment(date)
      //вернуть нужно int, а не строку
      return today.diff(pubDate, 'days')
    }


    const getBotUsers = pub =>{
      if (!pub.stats.bot) return 0
      //в pub.stats.bot лежит множество объектов с именем ключа в виде даты YYYY-mm-dd, нужно получить объект, который был через неделю после published_at
      const date = moment(pub.posted_at).add(7, 'days').format('YYYY-MM-DD')
      const stats = pub.stats.bot[date]
      if (stats){
        return stats.users_all
      }
      //а если такого нет, то нужно найти самую последнюю по дате запись
      let dates = Object.keys(pub.stats.bot);
      let latestDate = new Date(Math.max.apply(null, dates.map(date => new Date(date))));
      latestDate = moment(latestDate).format('YYYY-MM-DD')
      return pub.stats.bot[latestDate].users_all
    }

    const getChannelUsers = pub =>{
      if (!pub.stats.channel) return 0
      return pub.stats.channel.subs_1w
    }

    const getExtraStats = pub =>{
      //в pub.stats.bot лежит множество объектов с именем ключа в виде даты YYYY-mm-dd, нужно получить объект, который был через неделю после published_at
      const date = moment(pub.posted_at).add(7, 'days').format('YYYY-MM-DD')
      const stats = pub.stats.bot[date]
      if (stats){
        return{
          users_week: stats.users_all,
        }
      }
      return pub.stats.bot[date]
    }

    const fillStats = (channel) =>{
      // const users = filters.value.type === 'bot' ? channel.lastPub.extra.users_week : channel.lastPub.stats.channel.subs_1w
      const users = filters.value.userType === 'bot' ? getBotUsers(channel.lastPub) : getChannelUsers(channel.lastPub)
      const stats = {
        users,
        posted_at: moment(channel.lastPub.posted_at).format('DD.MM.YYYY'),
        posted_days_ago: getDaysAgo(channel.lastPub.posted_at),
        cpi: (channel.lastPub.price / users).toFixed(1),
        coeff: (users / channel.lastPub.views * 1000).toFixed(1),
        cpm: (channel.lastPub.price / channel.lastPub.views * 1000).toFixed(0),
      }
      //чтобы посчитать доход, придётся найти в lastPub.stats.bot последнюю дату (в формате YYYY-MM-DD), и взять оттуда money
      stats.income = channel.lastPub.stats.bot[Object.keys(channel.lastPub.stats.bot).sort((a,b)=>a<b?1:-1)[0]].money
      stats.profit = (stats.income - channel.lastPub.price).toFixed(1)
      return stats
    }

    const allPublications = computed(() => {
      return store.state.adPublications
    })
    const pubs = computed(() => {
      return allPublications.value
    })

    const formatDate = date => date==='0000-00-00 00:00:00' ? 'Ещё не вышло' : moment(date).format('DD.MM.YY')

    return {
      filters,
      pubs,
      channels,
      headers,
      formatDate, getDaysAgo,
    }
  }
}
</script>

<style scoped>

</style>
